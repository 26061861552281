import { configuration } from '../../configuration';
import { CampaignAssetModification } from '../../data/campaign-assets.js';
import { Campaign, CampaignDetail } from '../../data/campaign.js';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class CampaignService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'campaign');
  }

  public async remove(id: any): Promise<void> {
    await this.apiClient.delete(this.url(id));
  }

  public async edit(newCampaign: Campaign): Promise<void> {
    await this.apiClient.put<Campaign, Campaign>(this.url(), newCampaign);
  }

  public async add(newCampaign: Campaign): Promise<void> {
    await this.apiClient.post<Campaign, Campaign>(this.url(), newCampaign);
  }

  public async getCampaignById(id: string): Promise<Campaign> {
    return await this.apiClient.get<Campaign>(this.url(`by-id/${id}`));
  }

  public async publishCampaign(
    partnerSlug: string,
    published: boolean
  ): Promise<void> {
    await this.apiClient.put(this.url(`/publish/${partnerSlug}`), {
      published: published,
    });
  }

  public async getAll(partnerSlug: string): Promise<Campaign[]> {
    const result = await this.apiClient.get<Campaign[]>(this.url(partnerSlug));
    if (!result) {
      return [];
    }
    return result.filter(
      campaign => !campaign.campaign_id?.startsWith('DRAFT-')
    );
  }

  public async saveScreenShotsAssets(
    campaignAssetModification: CampaignAssetModification,
    campaignId: string
  ): Promise<void> {
    const formData = new FormData();
    const screenshots = campaignAssetModification.screenshots;
    if (
      screenshots &&
      screenshots.some(
        screenshot => screenshot instanceof File || screenshot === 'removed'
      )
    ) {
      screenshots.forEach(element => {
        formData.append(
          'files',
          element instanceof File
            ? element
            : new Blob([element], { type: 'text' })
        );
      });
      await this.apiClient.put(
        this.url(`/assets-screen-shots/${campaignId}`),
        formData
      );
    }
    return;
  }

  public async saveIconAsset(
    campaignAssetModification: CampaignAssetModification,
    campaignId: string
  ): Promise<void> {
    const formData = new FormData();
    const icon = campaignAssetModification?.icon;
    if ((icon && icon instanceof File) || icon === 'removed') {
      formData.append(
        'file',
        icon instanceof File ? icon : new Blob([icon], { type: 'text' })
      );
      await this.apiClient.put(
        this.url(`/assets-icon/${campaignId}`),
        formData
      );
    }
    return;
  }

  public async saveBackgroundAsset(
    campaignAssetModification: CampaignAssetModification,
    campaignId: string
  ): Promise<void> {
    const formData = new FormData();
    const background = campaignAssetModification?.background;
    if (
      (background && background instanceof File) ||
      background === 'removed'
    ) {
      formData.append(
        'file',
        background instanceof File
          ? background
          : new Blob([background], { type: 'text' })
      );
      await this.apiClient.put(
        this.url(`/assets-background/${campaignId}`),
        formData
      );
    }
    return;
  }

  public async savePoster(
    campaignAssetModification: CampaignAssetModification,
    campaignId: string
  ): Promise<void> {
    const formData = new FormData();
    const poster = campaignAssetModification?.poster;
    if ((poster && poster instanceof File) || poster === 'removed') {
      formData.append(
        'file',
        poster instanceof File ? poster : new Blob([poster], { type: 'text' })
      );
      await this.apiClient.put(
        this.url(`/assets-poster/${campaignId}`),
        formData
      );
    }
    return;
  }

  public async saveBannerAsset(
    campaignAssetModification: CampaignAssetModification,
    campaignId: string
  ): Promise<void> {
    const formData = new FormData();
    const banner = campaignAssetModification?.banner;
    if ((banner && banner instanceof File) || banner === 'removed') {
      formData.append(
        'file',
        banner instanceof File ? banner : new Blob([banner], { type: 'text' })
      );
      await this.apiClient.put(
        this.url(`/assets-banner/${campaignId}`),
        formData
      );
    }
    return;
  }

  public async saveWeekQuestsBgAsset(
    campaignAssetModification: CampaignAssetModification,
    campaignId: string
  ): Promise<void> {
    const formData = new FormData();
    const weekQuestsBg = campaignAssetModification?.week_quests_bg;
    if (
      (weekQuestsBg && weekQuestsBg instanceof File) ||
      weekQuestsBg === 'removed'
    ) {
      formData.append(
        'file',
        weekQuestsBg instanceof File
          ? weekQuestsBg
          : new Blob([weekQuestsBg], { type: 'text' })
      );
      await this.apiClient.put(
        this.url(`/assets-week-quests-bg/${campaignId}`),
        formData
      );
    }
    return;
  }

  public async getAllCampaignDetails(
    partnerSlug: string
  ): Promise<CampaignDetail[]> {
    return await this.apiClient.get<CampaignDetail[]>(
      this.url(`partner/${partnerSlug}`)
    );
  }
}

export const campaignService = new CampaignService(
  new BearerTokenApiClient(configuration.backendApi.url)
);
