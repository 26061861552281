import { Flex } from '@mantine/core';
import { FunctionComponent } from 'react';
import { Socials } from '../../../../data/campaign';
import SocialElement from './components/SocialElement';

export interface SocialSectionProps {
  socials: Socials;
  onSocialsChange?: (socials: Socials) => void;
}

const SocialSection: FunctionComponent<SocialSectionProps> = props => {
  const { socials, onSocialsChange } = props;

  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <SocialElement
        defaultInputValue={socials?.discord}
        fieldName={'discord'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultInputValue={socials?.twitter}
        fieldName={'twitter'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultInputValue={socials?.youtube}
        fieldName={'youtube'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultInputValue={socials?.telegram}
        fieldName={'telegram'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultInputValue={socials?.medium}
        fieldName={'medium'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
      <SocialElement
        defaultInputValue={socials?.opensea}
        fieldName={'opensea'}
        onUpdate={(fieldName, newValue) =>
          onSocialsChange?.({ ...socials, [fieldName]: newValue })
        }
      />
    </Flex>
  );
};

export default SocialSection;
