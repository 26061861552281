import { Skeleton } from '@chakra-ui/react';
import {
  Button,
  Divider,
  Drawer,
  Flex,
  Switch,
  Title,
  TypographyStylesProvider,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NoCampaignEmptyState from '../../components/Campaign/NoCampaignEmptyState/NoCampaignEmptyState';
import CampaignAccountOptions from '../../components/CampaignAccountOptions/CampaignAccountOptions';
import HintsDialog from '../../components/HintsDialog/HintsDialog';
import QuestAccordion from '../../components/Quests/QuestAccordion/QuestAccordion';
import { QuestManagementForm } from '../../components/Quests/QuestManagementForm/QuestManagementForm';
import { QuestsList } from '../../components/Quests/QuestsList/QuestsList';
import { GlobalContext } from '../../context/GlobalContext';
import { actionPageHints } from '../../data/action-page-hints';
import { MessageStatus } from '../../data/notify-options';
import { Path } from '../../data/paths';
import { Quest, QuestFetchResponse, SortStructure } from '../../data/quest';
import { TelegramChannel } from '../../data/telegram-channel';
import { QuestTemplate } from '../../data/templates';
import { staffCheck } from '../../helper/feature-flags';
import {
  getOrderByActive,
  getQuestNumber,
  preSetOrder,
} from '../../helper/quests';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useFromCheckList } from '../../hooks/use-from-checklist';
import { useNotification } from '../../hooks/use-notification';
import { useTier } from '../../hooks/use-tier';
import { actionFieldsService } from '../../services/action-fields-service';
import { organizationService } from '../../services/api-client/organization-service';
import { questService } from '../../services/api-client/quest-service';
import { ssPartnerService } from '../../services/api-client/ss-partner-service';
import { ssQuestService } from '../../services/api-client/ss-quest-service';
import { telegramBotService } from '../../services/api-client/telegram-bot-sercice';
import { twitterVerificationService } from '../../services/api-client/twitter-verification-service';
import { localStorageService } from '../../services/local-storage-service';
import { withAuthProtection } from '../../services/protect-route-element';
import { questDataService } from '../../services/quest-data-service';

const Page = () => {
  const [{ isFreeTier, component, isClose, setIsClose, setContactUsReason }] =
    useTier();
  const {
    loadingCampaignsOptions,
    selectedCampaignOption,
    selectedCampaignData,
    loadingSelectedCampaignData,
    setCampaignOptionsTrigger,
    campaignOptionsTrigger,
    selectedOrganization,
    setAddingCampaign,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [isFromCheckList, setIsFromChecklist] = useFromCheckList();
  const notification = useNotification();
  const [refresh, setRefresh] = useState(false);
  const [savingCampaign, setSavingCampaign] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState<ReactNode>('Add Quest');
  const [selectedTemplate, setSelectedTemplate] = useState<QuestTemplate>();
  const [selectedQuest, setSelectedQuest] = useState<Quest>();
  const [isOpen, { open, close }] = useDisclosure(false);
  const [templates, setTemplates] = useState<(string | null)[]>([]);
  const [savingConnectedAccount, setSavingConnectedAccount] = useState(false);
  const [saveConnectedAccountDisable, setSaveConnectedAccountDisable] =
    useState<boolean>(false);
  const [connectedAccountsValues, setConnectedAccountsValues] = useState<
    string[]
  >([]);
  const [groupBy, setGroupBy] = useState<string | null>(
    localStorageService.getActionGroupBy()
  );
  const [activeFirst, setActiveFirst] = useState(
    localStorageService.getActiveFirst()
  );
  const [addingDraft, setAddingDraft] = useState(false);

  const [
    questFetchResponse,
    isLoadingQuestFetchResponse,
    setQuestFetchResponse,
  ] = useDataFetcher<QuestFetchResponse>({
    serviceCall() {
      if (!selectedCampaignData?.campaign_id) {
        return Promise.resolve({ quests: [], templates: [] });
      }
      return questService.getQuestsByCampaignId(
        selectedCampaignData.campaign_id
      );
    },
    dependencies: [refresh, selectedCampaignData, activeFirst],
    conditionForExecution: !!selectedCampaignData?.campaign_id,
    onFinish(response) {
      if (!response || !response.quests) {
        return;
      }
      const orderedQuestByEnabled = getOrderByActive(
        activeFirst,
        response.quests
      );
      const templates = actionFieldsService.getOrderedTemplates(
        response.templates
      );
      const orderedQuests = preSetOrder(orderedQuestByEnabled);
      setQuestFetchResponse({ templates: templates, quests: orderedQuests });
    },
    onError(error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        `An error occurred while fetching the quests: ${error.response.data.message ?? 'Unknown error'}. Please try again`
      );
    },
  });

  const [telegramChannels, isLoadingTelegramChannels] = useDataFetcher<
    TelegramChannel[]
  >({
    serviceCall() {
      if (selectedOrganization) {
        return telegramBotService.getChannels(selectedOrganization.partnerSlug);
      }
    },
    dependencies: [selectedOrganization],
    conditionForExecution: !!selectedOrganization,
  });

  const [twitterUserId, loadingTwitterUserId] = useDataFetcher({
    serviceCall() {
      if (selectedCampaignData?.extra_data?.social?.twitter) {
        const userName =
          selectedCampaignData?.extra_data?.social?.twitter?.split('/').pop() ??
          '';
        return twitterVerificationService.getAccountIdFromUserName(userName);
      }
    },
    dependencies: [selectedCampaignData],
    conditionForExecution: !!selectedCampaignData,
    onError() {
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while fetching the twitter user id. Please try again.'
      );
    },
  });

  const [gamePlatform] = useDataFetcher<string>({
    serviceCall() {
      if (selectedOrganization) {
        return organizationService.getGamePlatformValue(
          selectedOrganization.partnerSlug
        );
      }
    },
    dependencies: [selectedOrganization],
    conditionForExecution: !!selectedOrganization,
    onError() {
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while fetching the game platform. Please try again.'
      );
    },
  });

  const [campaignAccountOptions, loadingCampaignAccountOptions] =
    useDataFetcher({
      serviceCall() {
        if (selectedCampaignOption) {
          return questService.getCampaignAccountsDetails(
            selectedCampaignOption.draft_campaign_id
          );
        }
      },
      dependencies: [selectedCampaignData, selectedCampaignOption, refresh],
      conditionForExecution: !!selectedCampaignOption,
      onError() {
        notification.notify(
          MessageStatus.Error,
          'Quest Management',
          'An error occurred while fetching the campaign account options. Please try again.'
        );
      },
      onFinish() {
        setSaveConnectedAccountDisable(true);
      },
    });

  useEffect(() => {
    const title = (
      <Flex direction={'column'} gap={'xs'}>
        <Title size={'1.2rem'}>
          {selectedQuest
            ? `Edit action "${selectedTemplate?.displayName}"`
            : `Add action "${selectedTemplate?.displayName}"`}
        </Title>
        <Title size={'0.9rem'} c={'gray'}>
          <TypographyStylesProvider>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedTemplate?.template_description ?? '',
              }}
            />
          </TypographyStylesProvider>
        </Title>
      </Flex>
    );
    setDrawerTitle(title);
  }, [selectedTemplate]);

  useEffect(() => {
    localStorageService.setActionGroupBy(groupBy);
  }, [groupBy]);

  useEffect(() => {
    localStorageService.setActiveFirst(activeFirst);
  }, [activeFirst]);

  useEffect(() => {
    const questTypes = questDataService.getQuestTypes(
      questFetchResponse?.quests
    );
    setTemplates(questTypes);
  }, [questFetchResponse]);

  const handleQuestEdit = (quest: Quest) => {
    setSelectedQuest(quest);
    open();
  };

  const handleSaveQuest = async (quest: Quest, isAddition?: boolean) => {
    try {
      setSavingCampaign(true);
      await ssQuestService.validate(quest);
      if (isAddition) {
        await questService.addQuest(quest);
      } else {
        await questService.editQuest(quest);
      }
      close();
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
      setRefresh(prev => !prev);
      if (isFromCheckList) {
        setIsFromChecklist(false);
        navigate('/home');
      }
    } catch (error: any) {
      console.error(error);
      notification.notifySelfServiceError({
        status: MessageStatus.Error,
        title: 'Quest Management',
        description: 'An error occurred while saving the quest. ',
        response: error.response,
      });
    } finally {
      setSavingCampaign(false);
    }
  };

  const handleSaveOrder = async (sortStructure: SortStructure) => {
    try {
      await questService.saveQuestOrder(sortStructure);
    } catch (error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while saving the quest order. Please try again.'
      );
    }
  };

  const getManagementForm = () => {
    if (!selectedCampaignData || !questFetchResponse?.templates) return <></>;
    return (
      <QuestManagementForm
        isAddition={!selectedQuest}
        isLoading={
          savingCampaign ||
          isLoadingQuestFetchResponse ||
          loadingTwitterUserId ||
          isLoadingTelegramChannels
        }
        campaignId={selectedCampaignData.campaign_id}
        tier={selectedOrganization?.tier ?? 'free'}
        isForgeStaff={staffCheck()}
        onSave={(quest, isAddition) => handleSaveQuest(quest, isAddition)}
        onCancel={close}
        onTemplateChange={setSelectedTemplate}
        onUpsellClick={() => upsellCheck('startTime')}
        metadataDirectedFields={actionFieldsService.getMetadataDirectedFields({
          quest: questFetchResponse.quests,
          selectedTemplate: selectedTemplate,
          twitterUserId: twitterUserId,
          selectedCampaignData: selectedCampaignData,
          telegramChannels: telegramChannels,
          gamePlatform: gamePlatform,
        })}
        currentQuest={selectedQuest}
        getQuestNumber={selectedTemplate =>
          getQuestNumber(questFetchResponse.quests, selectedTemplate)
        }
        questCount={questFetchResponse.quests.length}
        questTemplates={questFetchResponse?.templates}
        gamePage={Path.Game}
        telegramBotInformation={telegramChannels}
      />
    );
  };

  const upsellCheck = (reason: string) => {
    if (isFreeTier && !staffCheck()) {
      setContactUsReason(reason);
      setIsClose(false);
      return true;
    }
    return false;
  };

  const handleAddQuest = () => {
    if (upsellCheck('AddingQuest') === true) {
      return;
    }
    setSelectedQuest(undefined);
    open();
  };

  const getQuestElements = () => {
    if (!groupBy) {
      return (
        <QuestsList
          quests={questFetchResponse?.quests ?? []}
          onSortChange={handleSaveOrder}
          isLoading={
            isLoadingQuestFetchResponse ||
            loadingSelectedCampaignData ||
            loadingCampaignsOptions ||
            loadingTwitterUserId
          }
          onQuestToEdit={handleQuestEdit}
        />
      );
    }
    return (
      <QuestAccordion
        template={templates}
        quests={questFetchResponse?.quests ?? []}
        onSortChange={handleSaveOrder}
        isLoading={
          isLoadingQuestFetchResponse ||
          loadingSelectedCampaignData ||
          loadingTwitterUserId
        }
        onQuestToEdit={quest => {
          handleQuestEdit(quest);
        }}
      />
    );
  };

  const handleOnConnectedAccountSave = async () => {
    try {
      setSavingConnectedAccount(true);
      if (!selectedCampaignData) {
        throw new Error('No campaign selected');
      }
      await questService.updateCampaignAccounts(
        selectedCampaignData.campaign_id,
        connectedAccountsValues
      );
      setSaveConnectedAccountDisable(true);
      setRefresh(prev => !prev);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Quest Management',
        'An error occurred while updating the connected accounts. Please try again.'
      );
    } finally {
      setSavingConnectedAccount(false);
    }
  };

  const handleOnConnectedAccountChange = (selectedAccounts: string[]) => {
    setConnectedAccountsValues(selectedAccounts);
    setSaveConnectedAccountDisable(false);
  };

  const handelAddNewDraft = async () => {
    try {
      setAddingDraft(true);
      setAddingCampaign(true);
      if (!selectedOrganization) {
        return;
      }
      await ssPartnerService.addDraftCampaign(selectedOrganization.partnerSlug);
    } catch (error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error adding new draft: ${error?.response?.data?.message ?? 'Unknown error'}`
      );
    } finally {
      setAddingDraft(false);
      setAddingCampaign(false);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    }
  };

  if (
    !selectedCampaignData &&
    !selectedCampaignOption &&
    !loadingCampaignsOptions &&
    !loadingSelectedCampaignData &&
    !loadingCampaignAccountOptions &&
    !isLoadingQuestFetchResponse
  ) {
    return (
      <NoCampaignEmptyState
        addNewDraft={handelAddNewDraft}
        addingDraft={addingDraft}
      />
    );
  }

  return (
    <Flex direction={'column'}>
      {isFreeTier && !isClose && component}
      <Flex
        direction={'column'}
        pos={'sticky'}
        top={0}
        style={{ zIndex: 200 }}
        bg={'#f9fafb'}
      >
        <Flex align={'self-end'} gap={'md'} justify={'space-between'} p={'xs'}>
          <CampaignAccountOptions
            data={campaignAccountOptions}
            handleOnConnectedAccountChange={handleOnConnectedAccountChange}
            handleOnConnectedAccountSave={handleOnConnectedAccountSave}
            loadingData={
              loadingCampaignAccountOptions ||
              loadingCampaignsOptions ||
              loadingSelectedCampaignData
            }
            saveConnectedAccountDisable={saveConnectedAccountDisable}
            saving={savingConnectedAccount}
          />
          {loadingCampaignsOptions ||
          loadingSelectedCampaignData ||
          !selectedCampaignData ? (
            <Skeleton w={300} h={10} />
          ) : (
            <Flex align={'center'} gap={'md'} justify={'flex-end'} p={'xs'}>
              <Switch
                defaultChecked={!!activeFirst}
                onChange={e => setActiveFirst(e.currentTarget.checked)}
                label="Active first"
              ></Switch>
              <Switch
                defaultChecked={!!groupBy}
                onChange={e =>
                  setGroupBy(e.currentTarget.checked ? 'type' : '')
                }
                label="Group by type"
              ></Switch>

              <Button
                disabled={
                  loadingSelectedCampaignData ||
                  isLoadingQuestFetchResponse ||
                  loadingTwitterUserId
                }
                onClick={() => {
                  handleAddQuest();
                }}
              >
                Add
              </Button>
            </Flex>
          )}
        </Flex>
        <Divider />
      </Flex>
      <HintsDialog hints={actionPageHints} />
      <Flex
        className="QuestsPageWrapper"
        justify={'center'}
        align={'center'}
        direction={'column'}
        h={'100%'}
        w={'100%'}
        p={'md'}
      >
        {getQuestElements()}
        <Drawer
          key={'add-drawer'}
          zIndex={300}
          opened={isOpen}
          onClose={close}
          title={drawerTitle}
          position="right"
          size={'xl'}
        >
          {getManagementForm()}
        </Drawer>
      </Flex>
    </Flex>
  );
};

export const ActionPage = withAuthProtection(Page);
