export enum FeatureFlags {
  ShowApiKeyPage = 'show-api-key-page',
  ShowBillingPage = 'show-billing-page',
  ShowBillingPlan = 'show-billing-plan',
  ShowDiscordPage = 'show-discord-page',
  ShowKolsPage = 'show-kols-page',
  ShowTelegramBotPage = 'show-telegram-bot-page',
  ShowReportsPage = 'show-reports-page',
  ShowMultiCampaign = 'show-multi-campaign',
  ShowRewardsConfiguration = 'show-rewards-configuration',
}
