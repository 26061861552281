import { Flex, Tabs } from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { Campaign } from '../../data/campaign';
import { CampaignAssetModification } from '../../data/campaign-assets';
import NoCampaignEmptyState from '../Campaign/NoCampaignEmptyState/NoCampaignEmptyState';
import BasicInfoSection from './components/BasicAssetsSection';
import { CommonAssetsForm } from './components/CommonAssetsForm';
import {
  GamePageTabs,
  getTabTitle,
  tabsRegistry,
} from './components/data/tabs-data';

import { useFeatureFlagEnabled } from 'posthog-js/react';
import { RewardInfo } from '../../data/reward-info';
import { staffCheck } from '../../helper/feature-flags';
import ExtraDataSection from './components/Extra-DataSection';
import RewardSection from './components/RewardSection';
import { ScreenshotsForm } from './components/ScreenshotsForm';
import SocialSection from './components/Social/SocialSection';

const defaultRewardDataSet: RewardInfo = {
  reward_title: '',
  reward_description: '',
  reward_image: '',
  extra_data: {
    expected_distribution_date: '',
    distribution_process: '',
  },
};

export interface GameEditionSectionProps {
  campaign?: Campaign;
  rewardsData?: RewardInfo;
  addNewDraft?: () => void;
  addingDraft?: boolean;
  onGameChange: (
    editedCampaign: Campaign,
    newAssets: CampaignAssetModification,
    reward: RewardInfo
  ) => void;
  setSaveEnabled?: (enabled: boolean) => void;
  onPublishEnabled?: (enabled: boolean) => void;
}

const GameEditionSection: FunctionComponent<
  GameEditionSectionProps
> = props => {
  const {
    addNewDraft,
    addingDraft,
    onGameChange,
    setSaveEnabled,
    onPublishEnabled,
  } = props;
  const tabKeys = Array.from(tabsRegistry.keys()).filter(key => {
    const tab = tabsRegistry.get(key);
    return (
      !tab?.featureFlag ||
      useFeatureFlagEnabled(tab.featureFlag) ||
      staffCheck()
    );
  });

  const [campaign, setCampaign] = useState(props.campaign);
  const [reward, setReward] = useState(
    props.rewardsData ?? defaultRewardDataSet
  );
  const [publishEnabledFromBasicSection, setPublishEnabledFromBasicSection] =
    useState(false);
  const [
    publishEnabledFromExtraDataSection,
    setPublishEnabledFromExtraDataSection,
  ] = useState(false);
  const [publishEnabledFromCommonAssets, setPublishEnabledFromCommonAssets] =
    useState(false);
  const [commonAssets, setCommonAssets] = useState<CampaignAssetModification>(
    {}
  );
  useEffect(() => {
    onPublishEnabled?.(
      publishEnabledFromBasicSection &&
        publishEnabledFromExtraDataSection &&
        publishEnabledFromCommonAssets
    );
  }, [
    publishEnabledFromBasicSection,
    publishEnabledFromExtraDataSection,
    publishEnabledFromCommonAssets,
  ]);

  const [screenshotsAssets, setScreenshotsAssets] =
    useState<CampaignAssetModification>({});

  useEffect(() => {
    setCampaign(props.campaign);
  }, [props.campaign]);

  useEffect(() => {
    handleGameChange();
  }, [campaign, commonAssets, screenshotsAssets, reward]);

  const handleGameChange = () => {
    if (!campaign || !reward) {
      return;
    }
    const assets: CampaignAssetModification = {
      ...commonAssets,
      screenshots: screenshotsAssets.screenshots,
    };
    onGameChange(campaign, assets, reward);
  };

  const getContent = () => {
    if (!campaign) return;
    return (
      <Flex
        direction={'column'}
        w={'100%'}
        h={'100%'}
        align={'center'}
        justify={'center'}
      >
        <Tabs defaultValue={tabKeys[0]} w={'100%'}>
          <Tabs.List>
            {tabKeys
              .filter(x => x)
              .map(value => (
                <Tabs.Tab
                  key={value}
                  value={value}
                  leftSection={tabsRegistry.get(value)?.icon}
                >
                  {getTabTitle(value, campaign)}
                </Tabs.Tab>
              ))}
          </Tabs.List>
          <Tabs.Panel value={GamePageTabs.Information}>
            <BasicInfoSection
              title={campaign?.title}
              subtitle={campaign?.subtitle}
              onTitleChange={title => {
                const updatedCampaign = { ...campaign, title: title };
                setSaveEnabled?.(true);
                setCampaign(updatedCampaign);
              }}
              onSubtitleChange={subtitle => {
                const updatedCampaign = { ...campaign, subtitle: subtitle };
                setSaveEnabled?.(true);
                setCampaign(updatedCampaign);
              }}
              setPublishEnabled={setPublishEnabledFromBasicSection}
            />
            <ExtraDataSection
              campaign={campaign}
              onCampaignChange={(editedCampaign: Campaign) => {
                setSaveEnabled?.(true);
                setCampaign(editedCampaign);
              }}
              setPublishEnabled={setPublishEnabledFromExtraDataSection}
            />
          </Tabs.Panel>
          <Tabs.Panel value={GamePageTabs.Assets}>
            <CommonAssetsForm
              campaign={campaign}
              onAssetsChange={(assets: CampaignAssetModification) => {
                setSaveEnabled?.(true);
                setCommonAssets(assets);
              }}
              setPublishEnabled={setPublishEnabledFromCommonAssets}
            />
            <ScreenshotsForm
              campaign={campaign}
              onSaved={(assets: CampaignAssetModification) => {
                setSaveEnabled?.(true);
                setScreenshotsAssets(assets);
              }}
            />
          </Tabs.Panel>
          <Tabs.Panel value={GamePageTabs.Socials}>
            <SocialSection
              socials={campaign.extra_data.social}
              onSocialsChange={socials => {
                const updatedCampaign = {
                  ...campaign,
                  extra_data: { ...campaign.extra_data, social: socials },
                };
                setSaveEnabled?.(true);
                setCampaign(updatedCampaign);
              }}
            />
          </Tabs.Panel>
          <Tabs.Panel value={GamePageTabs.Rewards}>
            <RewardSection
              reward={reward}
              onRewardChange={(reward: RewardInfo) => {
                setSaveEnabled?.(true);
                setReward(reward);
              }}
            />
          </Tabs.Panel>
        </Tabs>
      </Flex>
    );
  };
  const getEmptyState = () => {
    return (
      <NoCampaignEmptyState
        addNewDraft={addNewDraft}
        addingDraft={addingDraft}
      />
    );
  };
  return (
    <Flex w={'70%'} h={'100%'}>
      {campaign ? getContent() : getEmptyState()}
    </Flex>
  );
};

export default GameEditionSection;
