import { Flex } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeaturedTweets } from '../../components/FeaturedTweets/FeaturedTweets';
import { data } from '../../config/config';
import { GlobalContext } from '../../context/GlobalContext';
import { FeaturedTweet } from '../../data/featured-tweet';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useFromCheckList } from '../../hooks/use-from-checklist';
import { useNotification } from '../../hooks/use-notification';
import { tweetsService } from '../../services/api-client/tweets-service';
import { twitterService } from '../../services/api-client/twitter-service';

import { MessageStatus } from '../../data/notify-options';
import { withAuthProtection } from '../../services/protect-route-element';
import { linkTwitterAccount } from '../../services/twitter-account-linker';
import './TweetsPage.scss';

const Page = () => {
  const navigate = useNavigate();
  const {
    selectedCampaignOption,
    selectedOrganization,
    loadingCampaignsOptions,
    loadingSelectedCampaignData,
    campaignOptionsTrigger,
    setCampaignOptionsTrigger,
  } = useContext(GlobalContext);
  const [isFromCheckList, setIsFromChecklist] = useFromCheckList();
  const notification = useNotification();
  const [addingTweet, setAddingTweet] = useState(false);
  const [publishingTweet, setPublishingTweet] = useState(false);
  const [badTweetCode, setBadTweetCode] = useState(false);
  const [canCloseDrawer, setCanCloseDrawer] = useState(true);
  const [unlinkTrigger, setUnlinkTrigger] = useState(false);
  const [removingTweet, setRemovingTweet] = useState(false);

  const [tweets, loading, setTweets] = useDataFetcher<FeaturedTweet[]>({
    serviceCall() {
      if (selectedCampaignOption) {
        return tweetsService.getFeaturedTweets(
          selectedCampaignOption.draft_campaign_id
        );
      }
    },
    dependencies: [selectedCampaignOption],
  });
  const [twitterAccount, loadingTwitterAccount] = useDataFetcher({
    serviceCall: () => {
      if (!selectedOrganization) {
        throw new Error('Unexpected state, no organization selected');
      }
      return twitterService.getLinkedAccount(selectedOrganization.partnerSlug);
    },
    dependencies: [unlinkTrigger],
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const error = queryParams.get('error');

    if (!notification || !error) {
      return;
    }

    notification.notify(
      MessageStatus.Error,
      'Failed to link Twitter account',
      error
    );
  }, [notification]);

  const extractTweetId = (contents: string) => {
    const regex = /status\/([0-9]+)?/;
    const match = regex.exec(contents);
    if (match) {
      return match[1];
    }
    return '';
  };

  const handleAddTweet = async (tweetCode: string) => {
    try {
      if (!tweets || !selectedOrganization || !selectedCampaignOption) {
        return;
      }
      setCanCloseDrawer(false);
      setAddingTweet(true);
      setBadTweetCode(false);
      const tweetId = extractTweetId(tweetCode);
      if (tweetId === '') {
        setBadTweetCode(true);
        return;
      }
      if (tweets.some(t => t.id === tweetId)) {
        notification.notify(
          MessageStatus.Error,
          'Tweet already exists',
          'This tweet is already added to the list'
        );
        return;
      }

      const newTweet = await tweetsService.addFeaturedTweet(
        tweetId,
        tweetCode,
        selectedOrganization.partnerSlug,
        selectedCampaignOption.draft_campaign_id
      );
      setTweets([...tweets, newTweet]);
      setCanCloseDrawer(true);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Failed to add tweet',
        'An error occurred while adding the tweet'
      );
    } finally {
      setAddingTweet(false);
      if (isFromCheckList) {
        setIsFromChecklist(false);
        navigate('/home');
      }
    }
  };

  const handleTwitterAccountLinking = async () => {
    if (!selectedOrganization) {
      throw new Error('Unexpected state, no organization selected');
    }
    await linkTwitterAccount(
      selectedOrganization.partnerSlug,
      '/tweets',
      '/tweets'
    );
    setCampaignOptionsTrigger(!campaignOptionsTrigger);
  };

  const handlePublishTweet = async (tweetCode: string) => {
    try {
      if (!tweets || !selectedCampaignOption) {
        return;
      }
      setPublishingTweet(true);
      setBadTweetCode(false);
      let tweetId = extractTweetId(tweetCode);
      if (tweetId === '') {
        setBadTweetCode(true);
        return;
      }
      const tempTweets = [...tweets];
      const index = tempTweets.findIndex(t => t.id === tweetId);
      if (tempTweets[index].active) {
        tweetId = '';
      }
      tempTweets[index].active = !tempTweets[index].active;
      tempTweets
        .filter(t => t.id !== tempTweets[index].id)
        .map(t => (t.active = false));
      await tweetsService.publishFeaturedTweet(
        [tweetId],
        selectedCampaignOption?.draft_campaign_id
      );
      setTweets(tempTweets);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error: any) {
      console.error(error);
      notification.notifySelfServiceError({
        status: MessageStatus.Error,
        title: 'Failed to publish tweet',
        description: 'An error occurred while publishing the tweet',
        response: error?.response?.data?.message,
      });
    } finally {
      setPublishingTweet(false);
    }
  };
  const copyToClipboard = (code: string) => {
    navigator.clipboard.writeText(code);
    notification.notify(
      MessageStatus.Success,
      'Copied to clipboard',
      'The tweet code was copied'
    );
  };
  const handleInvalidEmbeddedCode = () => {
    setBadTweetCode(true);
    notification.notify(
      MessageStatus.Error,
      'Invalid tweet code',
      'The tweet embedded code is invalid.'
    );
  };

  const handleAccountUnlink = async () => {
    try {
      if (!twitterAccount || !selectedOrganization) {
        throw new Error(
          'Unexpected state, no Twitter account linked or organization selected'
        );
      }
      console.log('unlinking account', twitterAccount);
      console.log('selectedOrg', selectedOrganization);
      await twitterService.unlinkAccount(
        twitterAccount.id,
        selectedOrganization.partnerSlug
      );
      setUnlinkTrigger(!unlinkTrigger);
    } catch (error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Failed to unlink Twitter account',
        'An error occurred while unlinking the Twitter account'
      );
    } finally {
      window.location.reload();
    }
  };

  const handleDeleteTweet = async (tweetsToRemove: FeaturedTweet[]) => {
    try {
      setRemovingTweet(true);
      if (!tweets || !selectedCampaignOption) {
        return;
      }
      if (tweetsToRemove.some(t => t.active)) {
        notification.notify(
          MessageStatus.Warning,
          'Cannot delete active tweet',
          'Please deactivate the tweet before deleting it'
        );
        return;
      }
      const tweetIdsToRemove = tweetsToRemove.map(t => t.id);
      await tweetsService.deleteFeaturedTweets(
        tweetIdsToRemove,
        selectedCampaignOption.draft_campaign_id
      );
      setTweets(tweets.filter(t => !tweetsToRemove.includes(t)));
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error) {
      console.error(error);
      notification.notify(
        MessageStatus.Error,
        'Failed to delete tweet',
        'An error occurred while deleting the tweet'
      );
    } finally {
      setRemovingTweet(false);
    }
  };

  return (
    <Flex className="TweetsPageWrapper" justify={'center'} align={'center'}>
      <FeaturedTweets
        removingTweet={removingTweet}
        canCloseDrawer={canCloseDrawer}
        onInvalidEmbeddedCode={() => handleInvalidEmbeddedCode()}
        onCopyCode={code => copyToClipboard(code)}
        addingTweet={addingTweet}
        badTweetCode={badTweetCode}
        publishingTweet={publishingTweet}
        readOnly={data.isFeaturedTweetsReadOnly}
        isLoading={
          loading || loadingCampaignsOptions || loadingSelectedCampaignData
        }
        onUnlinkAccount={() => handleAccountUnlink()}
        onDeleteTweets={tweets => handleDeleteTweet(tweets)}
        onPublish={(tweetCode: string) => {
          handlePublishTweet(tweetCode);
        }}
        onAdd={(tweetCode: string) => {
          handleAddTweet(tweetCode);
        }}
        onLinkTwitterAccount={() => handleTwitterAccountLinking()}
        tweets={tweets}
        twitterLinkedAccount={twitterAccount || null}
        isLoadingTwitterAccount={loadingTwitterAccount}
      />
    </Flex>
  );
};

export const TweetsPage = withAuthProtection(Page);
