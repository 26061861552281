import { Text } from '@mantine/core';
import {
  IconAsset,
  IconGift,
  IconInfoCircle,
  IconSocial,
} from '@tabler/icons-react';
import { ReactNode } from 'react';
import { Campaign } from '../../../../data/campaign';
import { FeatureFlags } from '../../../../data/feature-flags';
import { campaignDataService } from '../../../../services/campaign-data-service';

export interface ErrorByTabElement {
  tab: GamePageTabs;
  section?: string;
  inError: boolean;
}

export enum GamePageTabs {
  Information = 'information',
  Assets = 'assets',
  Socials = 'socials',
  Rewards = 'rewards',
}

const iconStyle = { width: '1.3rem', height: '1.3rem' };

export const tabsRegistry = new Map<
  GamePageTabs,
  { title: string; icon: ReactNode; featureFlag?: FeatureFlags }
>([
  [
    GamePageTabs.Information,
    {
      title: 'Information',
      icon: <IconInfoCircle style={iconStyle} />,
    },
  ],
  [
    GamePageTabs.Assets,
    { title: 'Assets', icon: <IconAsset style={iconStyle} /> },
  ],
  [
    GamePageTabs.Socials,
    { title: 'Socials', icon: <IconSocial style={iconStyle} /> },
  ],
  [
    GamePageTabs.Rewards,
    {
      title: 'Rewards',
      icon: <IconGift style={iconStyle} />,
      featureFlag: FeatureFlags.ShowRewardsConfiguration,
    },
  ],
]);
export const getTabTitle = (tab: GamePageTabs, campaign: Campaign) => {
  const tabsWithErrors =
    campaignDataService.getCampaignPublishableStatus(campaign);
  const color = tabsWithErrors.sectionsInError?.map(x => x).includes(tab)
    ? 'red'
    : '';
  return <Text c={color}>{tabsRegistry.get(tab)?.title}</Text>;
};
