import { Button, Flex, Image, Loader, Modal, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';
import React, { useState } from 'react'; // Adjust the import according to your project structure
import { LocalImages } from '../../../assets/images';
import { CampaignOption } from '../../../data/campaign-option';

interface DeletingCampaignDialogProps {
  deletingCampaign?: boolean;
  selectedCampaign: CampaignOption | null;
  campaignOption: CampaignOption;
  handleCampaignDelete?: (campaignOption: CampaignOption) => void;
}

const DeletingCampaignDialog: React.FC<DeletingCampaignDialogProps> = props => {
  const {
    campaignOption,
    selectedCampaign,
    deletingCampaign,
    handleCampaignDelete,
  } = props;
  const [opened, { open, close }] = useDisclosure();
  const [campaignToDelete, setCampaignToDelete] =
    useState<CampaignOption | null>(null);

  const getComponentColor = (id: string) => {
    if (id === selectedCampaign?.draft_campaign_id) {
      return '#ffffff';
    }
  };

  const getTrashComponent = () => {
    if (!campaignOption || campaignOption.published) {
      return <></>;
    }
    if (
      deletingCampaign === true &&
      selectedCampaign?.draft_campaign_id === campaignOption.draft_campaign_id
    ) {
      return (
        <Loader
          color={getComponentColor(campaignOption.draft_campaign_id)}
          size={'1rem'}
        />
      );
    }

    return (
      <IconTrash
        style={{ cursor: 'pointer', zIndex: 1000 }}
        onClick={() => {
          setCampaignToDelete(campaignOption);
          open();
        }}
        color={getComponentColor(campaignOption.draft_campaign_id)}
        size={'1rem'}
      />
    );
  };

  const confirmDelete = () => {
    if (campaignToDelete) {
      handleCampaignDelete?.(campaignToDelete);
      close();
    }
  };

  return (
    <>
      <Modal
        title={<Image w={'8rem'} src={LocalImages.logo} />}
        centered
        zIndex={300}
        opened={opened}
        onClose={close}
      >
        <Flex direction={'column'} gap={'1rem'}>
          <Title order={3}>Removing a draft campaign</Title>
          <Flex direction={'column'} gap={'0.5rem'}>
            <Text c={'gray'}>
              {`Are you sure you want to delete the campaign ${campaignToDelete?.title}?`}
            </Text>
          </Flex>
          <Flex gap={'1rem'} w={'100%'} justify={'end'}>
            <Button onClick={close}>Cancel</Button>
            <Button color="red" onClick={confirmDelete}>
              Delete
            </Button>
          </Flex>
        </Flex>
      </Modal>
      {getTrashComponent()}
    </>
  );
};

export default DeletingCampaignDialog;
