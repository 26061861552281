import { useFeatureFlagEnabled } from 'posthog-js/react';
import { configuration } from '../configuration';
import { FeatureFlags } from '../data/feature-flags';
import { authentication } from '../services/authentication';

export const staffCheck = () => {
  return isStaffUser() || isAutomationEmail();
};

const reportWhiteList = () => {
  const user = authentication.getUser();
  user?.email?.endsWith('@sandbox.game');
};

const isStaffUser = () => {
  const user = authentication.getUser();
  if (!user || !user.email) {
    return false;
  }
  const userEmailDomain = `@${user.email.split('@')[1]}`;
  return configuration.automation.staffEmailDomains
    .split(',')
    .includes(userEmailDomain);
};

const isAutomationEmail = () => {
  const user = authentication.getUser();
  if (!user || !user.email) {
    return false;
  }
  return configuration.automation.automationEmails.includes(user.email);
};

export const getShowDiscordPage = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowDiscordPage) || staffCheck();
export const getShowBillingPage = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowBillingPage) || staffCheck();
export const getShowKolsPage = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowKolsPage) || staffCheck();
export const getShowTelegramBotPage = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowTelegramBotPage) || staffCheck();
export const getShowReportsPage = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowReportsPage) ||
  staffCheck ||
  reportWhiteList;

export const getShowRewardsConfiguration = () =>
  useFeatureFlagEnabled(FeatureFlags.ShowRewardsConfiguration);
