import { Flex, Text, Tooltip } from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { CampaignOption } from '../../../data/campaign-option';
import AddCampaignDialog from '../AddCampaignDialog/AddCampaignDialog';
import DeletingCampaignDialog from '../DeletingCampaignDialog/DeletignCampaignDialog';

export interface CampaignSelectorProps {
  campaignOptions?: CampaignOption[];
  selectedCampaignId?: string;
  onCampaignSelection?: (campaignOption: CampaignOption) => void;
  onAddCampaign?: (cloneOption: boolean) => void;
  handleCampaignDelete?: (campaignOption: CampaignOption) => void;
  loadingCampaignsOptions?: boolean;
  deletingCampaign?: boolean;
  addingCampaign?: boolean;
  isModalOpen: boolean;
}

const CampaignSelector: FunctionComponent<CampaignSelectorProps> = props => {
  const {
    campaignOptions,
    onCampaignSelection,
    handleCampaignDelete,
    deletingCampaign,
    selectedCampaignId,
    loadingCampaignsOptions,
    addingCampaign,
    onAddCampaign,
  } = props;
  const [selectedCampaign, setSelectedCampaign] =
    useState<CampaignOption | null>(null);
  const [isAddingDialogOpen, setIsAddingDialogOpen] = useState<boolean>(
    props.isModalOpen
  );

  useEffect(() => {
    setIsAddingDialogOpen(props.isModalOpen ?? false);
  }, [props.isModalOpen]);

  useEffect(() => {
    if (!campaignOptions) {
      return;
    }
    const selectedCampaignOption =
      campaignOptions.find(
        campaignOption =>
          campaignOption.draft_campaign_id === selectedCampaignId
      ) ?? campaignOptions[0];
    setSelectedCampaign(selectedCampaignOption);
    onCampaignSelection?.(selectedCampaignOption);
  }, [selectedCampaignId, campaignOptions]);

  if (!campaignOptions) {
    return <></>;
  }

  const getColor = (campaignOption: CampaignOption) => {
    return selectedCampaign?.draft_campaign_id ===
      campaignOption.draft_campaign_id
      ? '#ffffff'
      : '';
  };

  const handleCampaignSelection = (campaignOption: CampaignOption) => () => {
    setSelectedCampaign(campaignOption);
    onCampaignSelection?.(campaignOption);
  };

  const getCampaignElements = () => {
    if (loadingCampaignsOptions) {
      return (
        <Flex gap={'1rem'} w={'100%'} align={'center'}>
          <Text>Loading...</Text>
        </Flex>
      );
    }
    return (
      <Flex gap={'0.3rem'}>
        {campaignOptions.map((campaignOption, index) => {
          return (
            <Tooltip
              openDelay={500}
              key={`selector-tool-tip-${index}`}
              label={campaignOption.title}
            >
              <Flex
                miw={'5rem'}
                key={`selector-container-${index}`}
                gap={'0.3rem'}
                direction={'column'}
                bd={'1px solid #1c7ed6'}
                bg={
                  selectedCampaign?.draft_campaign_id ===
                  campaignOption.draft_campaign_id
                    ? '#1c7ed6'
                    : ''
                }
                p={'0.5rem'}
                style={{ cursor: 'pointer', borderRadius: '0.5rem' }}
                onClick={handleCampaignSelection(campaignOption)}
              >
                <Text
                  key={`selector-name-${index}`}
                  c={getColor(campaignOption)}
                  size={'1rem'}
                >
                  {campaignOption.title.length >= 15
                    ? `${campaignOption.title.substring(0, 15)} ...`
                    : campaignOption.title}
                </Text>
                <Flex direction={'row'} justify={'space-between'}>
                  <Text
                    key={`selector-is-published-tip-${index}`}
                    c={getColor(campaignOption)}
                    size={'0.7rem'}
                  >
                    {campaignOption.published ? 'live' : 'draft'}
                  </Text>
                  <DeletingCampaignDialog
                    deletingCampaign={deletingCampaign}
                    handleCampaignDelete={handleCampaignDelete}
                    selectedCampaign={selectedCampaign}
                    campaignOption={campaignOption}
                  />
                </Flex>
              </Flex>
            </Tooltip>
          );
        })}
      </Flex>
    );
  };

  const getAddButton = () => {
    if (loadingCampaignsOptions) {
      return;
    }
    return (
      <AddCampaignDialog
        opened={isAddingDialogOpen}
        isLoading={addingCampaign}
        onConfirm={(cloneOption: boolean) => onAddCampaign?.(cloneOption)}
        campaignOptions={campaignOptions}
      />
    );
  };
  return (
    <Flex gap={'1rem'} w={'100%'} align={'center'}>
      {getCampaignElements()}
      {getAddButton()}
    </Flex>
  );
};

export default CampaignSelector;
