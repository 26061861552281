import { Flex, Text, Title } from '@mantine/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { Campaign } from '../../../data/campaign';
import { CampaignAssetModification } from '../../../data/campaign-assets';
import ImageUploader from '../../Images/ImageUploader/ImageUploader';

export interface CommonAssetsFormProps {
  campaign: Campaign;
  onAssetsChange?: (elementsToSave: CampaignAssetModification) => void;
  savingCampaignAssets?: boolean;
  setPublishEnabled?: (enabled: boolean) => void;
}

export const CommonAssetsForm: FunctionComponent<
  CommonAssetsFormProps
> = props => {
  const [campaignAssets, setCampaignAssets] =
    useState<CampaignAssetModification>({
      background: props.campaign.extra_data?.background,
      icon: props.campaign.extra_data?.icon,
      banner: props.campaign.extra_data?.banner,
      week_quests_bg: props.campaign.extra_data?.week_quests_bg,
      poster: props.campaign.extra_data?.poster,
    });

  useEffect(() => {
    props.setPublishEnabled?.(shouldBePublishEnable());
  }, [campaignAssets]);

  const shouldBePublishEnable = () => {
    const isValid = Object.entries(campaignAssets).every(([key, value]) => {
      if (['banner', 'screenshots'].includes(key)) {
        return true;
      }
      if (['removed', ''].includes(value)) return false;
      return true;
    });
    return isValid;
  };

  const handleImageChange = (
    key: keyof CampaignAssetModification,
    image: File
  ) => {
    const updatedAssets = { ...campaignAssets, [key]: image };
    setCampaignAssets(updatedAssets);
    props.onAssetsChange?.(updatedAssets);
  };

  const handleAssetRemove = (key: keyof CampaignAssetModification) => {
    if (key === 'screenshots') {
      throw new Error('Cannot remove screenshots this way');
    }
    const updatedAssets = { ...campaignAssets, [key]: 'removed' };
    setCampaignAssets(updatedAssets);
    props.onAssetsChange?.(updatedAssets);
  };

  const getTitleWithDescription = (
    title: string,
    description: string,
    errorOnDelete?: string,
    isOptional?: boolean
  ) => {
    return (
      <Flex direction={'column'} justify={'center'} gap={'0.15rem'}>
        <Flex gap={'0.2rem'} align={'center'}>
          <Title order={6}>{title}</Title>
          {!isOptional && <Text c={'red'}>*</Text>}
        </Flex>
        {errorOnDelete && (
          <Text size={'xs'} c={'red'}>
            {errorOnDelete}
          </Text>
        )}
        <Text c={'#868e96'} size="xs">
          {description}
        </Text>
      </Flex>
    );
  };

  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <Flex
        gap={'2rem'}
        direction={'column'}
        justify={'space-between'}
        w={'100%'}
      >
        <Flex direction={'column'} gap={'1rem'}>
          {getTitleWithDescription(
            'Icon',
            'Upload a 120 x 120 png with transparent background'
          )}
          <ImageUploader
            allowMultipleImages={false}
            actualImage={campaignAssets.icon ?? props.campaign.extra_data?.icon}
            expectedDimensions="120x120"
            onImageChange={images => handleImageChange('icon', images[0])}
            style={{ width: '8.25rem' }}
          />
        </Flex>
        <Flex direction={'column'} gap={'1rem'} w={'100%'}>
          {getTitleWithDescription(
            'Background',
            'Upload a 1440 x 900 background image with no text or logos'
          )}
          <ImageUploader
            withRoundedBorders={true}
            allowMultipleImages={false}
            expectedDimensions="1440x900"
            actualImage={
              campaignAssets.background ?? props.campaign.extra_data?.background
            }
            onImageChange={images => handleImageChange('background', images[0])}
          />
        </Flex>
        <Flex direction={'column'} gap={'1rem'} w={'100%'}>
          {getTitleWithDescription(
            'Box Art',
            'Upload a 300 x 390 image of your game’s box art'
          )}
          <ImageUploader
            withRoundedBorders={true}
            allowMultipleImages={false}
            expectedDimensions="300x390"
            actualImage={
              campaignAssets.poster ?? props.campaign.extra_data?.poster
            }
            onImageChange={images => handleImageChange('poster', images[0])}
          />
        </Flex>

        <Flex direction={'column'} gap={'1rem'} w={'100%'}>
          {getTitleWithDescription(
            'Banner',
            'Upload a 1280 x 200 image',
            (!campaignAssets.banner && !props.campaign.extra_data?.banner) ||
              campaignAssets.banner === 'removed'
              ? 'Removing or not setting this asset will have an impact to the campaign page layout.'
              : '',
            true
          )}
          <ImageUploader
            withRoundedBorders={true}
            allowMultipleImages={false}
            withCloseButton={true}
            onRemoveButtonClicked={() => handleAssetRemove('banner')}
            expectedDimensions="1280x200"
            actualImage={
              campaignAssets.banner ?? props.campaign.extra_data?.banner
            }
            onImageChange={images => handleImageChange('banner', images[0])}
          />
        </Flex>
        <Flex direction={'column'} gap={'1rem'} w={'100%'}>
          {getTitleWithDescription(
            'User Points Background',
            'Upload a 520 x 120 image'
          )}
          <ImageUploader
            withRoundedBorders={true}
            allowMultipleImages={false}
            expectedDimensions="520x120"
            actualImage={
              campaignAssets.week_quests_bg ??
              props.campaign.extra_data?.week_quests_bg
            }
            onImageChange={images =>
              handleImageChange('week_quests_bg', images[0])
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
