import {
  Button,
  Card,
  CloseButton,
  Flex,
  Image,
  Overlay,
  Paper,
} from '@mantine/core';
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FaEdit, FaPlusCircle } from 'react-icons/fa';
import './ImageUploader.scss';

export interface ImageUploaderProps {
  actualImage: string | File;
  onImageChange: (image: File[]) => void;
  onRemoveButtonClicked?: () => void;
  imageShape?: 'rounded';
  expectedDimensions?: string;
  withCloseButton?: boolean;
  withRoundedBorders?: boolean;
  allowMultipleImages?: boolean;
  style?: React.CSSProperties;
}

const ImageUploader: FunctionComponent<ImageUploaderProps> = props => {
  const [isPlaceHolder] = useState(props.actualImage === 'noImage');
  const processImage = (image: string | File) => {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    }
    return image;
  };
  const openRef = useRef<() => void>(null);
  const [image, setImage] = useState<string>(processImage(props.actualImage));

  useEffect(() => {
    const image = processImage(props.actualImage);
    setImage(image);
  }, [props.actualImage]);

  const handleUpload = async (files: FileWithPath[]) => {
    props.onImageChange(files);
  };

  const isClickableInput =
    isPlaceHolder || ['noImage', ''].includes(props.actualImage as string);

  const getBorder = () => {
    return ['removed', ''].includes(image) && !props.withCloseButton
      ? '1px solid red'
      : '';
  };

  return (
    <Flex direction={'column'} gap={'0.2rem'} h={'100%'} style={props.style}>
      <Card
        bd={getBorder()}
        h={'100%'}
        shadow="sm"
        radius="md"
        withBorder
        padding={isPlaceHolder ? 'xs' : '0.5rem'}
      >
        <Flex align={'center'} justify={'flex-end'}>
          {props.withCloseButton && (
            <CloseButton
              size={'sm'}
              bg={'transparent'}
              onClick={() => {
                props.onRemoveButtonClicked?.();
              }}
            />
          )}
        </Flex>
        <Paper
          className={isClickableInput && isPlaceHolder ? 'ClickableImage' : ''}
        >
          <Image
            h={'100%'}
            mah={'25rem'}
            src={image}
            fallbackSrc={`https://placehold.co/${props.expectedDimensions ?? '700x500?text=Upload+new'}`}
            fit="cover"
          />
          <Overlay
            className="PlusIcon"
            color="#fff"
            backgroundOpacity={1}
            onClick={() => {
              if (isClickableInput) {
                openRef.current?.();
              }
            }}
          >
            <FaPlusCircle
              size={'2rem'}
              color="var(--mantine-primary-color-6)"
            />
          </Overlay>
        </Paper>
        <Dropzone
          openRef={openRef}
          multiple={props.allowMultipleImages}
          accept={IMAGE_MIME_TYPE}
          onDrop={(files: FileWithPath[]) => handleUpload(files)}
        />
      </Card>
      <Flex align={'center'} justify={'end'}>
        {image !== 'noImage' && (
          <Button
            size="compact-xs"
            leftSection={<FaEdit cursor={'pointer'} />}
            onClick={() => {
              openRef.current?.();
            }}
          >
            Edit
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default ImageUploader;
