import { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { GlobalContext } from './context/GlobalContext';
import { noContentPages, Path } from './data/paths';
import {
  getShowBillingPage,
  getShowDiscordPage,
  getShowKolsPage,
  getShowReportsPage,
  getShowTelegramBotPage,
  staffCheck,
} from './helper/feature-flags';
import { EmptyLayout } from './layout/EmptyLayout/EmptyLayout';
import { MainLayout } from './layout/MainLayout/MainLayout';
import { ActionPage } from './pages/ActionPage/ActionPage';
import { ApiPage } from './pages/ApiPage/ApiPage';
import { BillingPage } from './pages/BillingPage/BillingPage';
import { CollaboratorsPage } from './pages/Collaborators/CollaboratorsPage';
import { DiscordPage } from './pages/DiscordPage/DiscordPage';
import { GamePage } from './pages/GamePage/GamePage';
import { HomePage } from './pages/HomePage/HomePage';
import { KOLsPage } from './pages/KOLsPage/KOLsPage';
import { LoginCallbackPage } from './pages/LoginCallbackPage/LoginCallbackPage';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { OrganizationManagementPage } from './pages/OrganizationsManagmentPage/OrganizationManagementPage';
import { ReportPage } from './pages/ReportPage/ReportPage';
import { TelegramBotPage } from './pages/TelegramBotPage/TelegtamBotPage';
import { TweetsPage } from './pages/TweetsPage/TweetsPage';
import { UnauthorizePage } from './pages/UnauthorizePage/UnauthorizePage';
import { UserLookupPage } from './pages/UserLookupPage/UserLookupPage';
import { ssPartnerService } from './services/api-client/ss-partner-service';
import { authentication } from './services/authentication';
import { setupService } from './services/setup-service';

export const Kernel = () => {
  const {
    setCampaignOptions,
    setSelectCampaignOption,
    setSelectedOrganization,
  } = useContext(GlobalContext);
  const email = authentication.getUser()?.email;
  const notContentPagesConditions = [
    noContentPages.includes(window.location.pathname),
    !email,
  ];
  const updateSetup = async () => {
    const user = authentication.getUser();
    if (!user || !user.email) {
      return [];
    }
    const selectedOrganization = await setupService.setUpOrganizations(
      user.email
    );
    if (!selectedOrganization) {
      authentication.logout('invalid_user');
      return;
    }
    const campaignOptions = await ssPartnerService.getDraftCampaigns(
      selectedOrganization.partnerSlug
    );
    setSelectedOrganization(selectedOrganization);
    setSelectCampaignOption(campaignOptions[0]);
    setCampaignOptions(campaignOptions);
  };

  useEffect(() => {
    if (!notContentPagesConditions.some(condition => condition === true)) {
      updateSetup();
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path={Path.Home} element={<HomePage />} />
        {getShowReportsPage() && (
          <Route path={Path.Report} element={<ReportPage />} />
        )}
        <Route path={Path.Actions} element={<ActionPage />} />
        <Route path={Path.Tweets} element={<TweetsPage />} />
        <Route path={Path.Collaborators} element={<CollaboratorsPage />} />
        {getShowDiscordPage() && (
          <Route path={Path.Discord} element={<DiscordPage />} />
        )}
        {getShowKolsPage() && <Route path={Path.Kols} element={<KOLsPage />} />}
        {staffCheck() && (
          <Route
            path={Path.OrganizationManagement}
            element={<OrganizationManagementPage />}
          />
        )}

        <Route path={Path.Api} element={<ApiPage />} />
        <Route path={Path.Game} element={<GamePage />} />
        <Route path={Path.UserLookup} element={<UserLookupPage />} />
        {getShowBillingPage() && (
          <Route path={Path.Billing} element={<BillingPage />} />
        )}
        {getShowTelegramBotPage() && (
          <Route path={Path.TelegramBot} element={<TelegramBotPage />} />
        )}
        <Route index element={<HomePage />} />
      </Route>
      <Route path="/" element={<EmptyLayout />}>
        <Route path={Path.Login} element={<LoginPage />} />
        <Route path={Path.LoginCallback} element={<LoginCallbackPage />} />
        <Route path={Path.Unauthorized} element={<UnauthorizePage />} />
      </Route>
    </Routes>
  );
};
