import { Flex, Image, Textarea, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { FunctionComponent, useEffect, useState } from 'react';
import { RewardInfo } from '../../../data/reward-info';
import { formatDate } from '../../../helper/dates';
import { isValidLink } from '../../../helper/validators';

export interface RewardSectionProps {
  reward: RewardInfo;
  onRewardChange?: (reward: RewardInfo) => void;
}

const RewardSection: FunctionComponent<RewardSectionProps> = props => {
  const { onRewardChange } = props;
  const [internalReward, setReward] = useState<RewardInfo>(props.reward);
  const [title, setTitle] = useState(props.reward?.reward_title);
  const [description, setDescription] = useState(
    props.reward?.reward_description
  );
  const [image, setImage] = useState(props.reward?.reward_image);
  const [expectedDate, setExpectedDate] = useState(
    props.reward?.extra_data?.expected_distribution_date
  );
  const [distributionProcess, setDistributionProcess] = useState(
    props.reward?.extra_data?.distribution_process
  );

  useEffect(() => {
    setReward(props.reward);
  }, [props.reward]);

  const getRewardDescriptionError = (rewardDescription?: string) => {
    if (!rewardDescription) {
      return 'Reward Description is required';
    }
    if (rewardDescription.length < 50) {
      return 'Reward Description should be more than 50 characters';
    }
  };

  return (
    <Flex gap={'1rem'} p={'1rem'} direction={'column'} w={'100%'}>
      <TextInput
        label="Reward Title"
        value={title}
        onChange={event => {
          setTitle(event.currentTarget.value);
          onRewardChange?.({
            ...internalReward,
            reward_title: event.currentTarget.value,
          });
        }}
        required
        error={!title && 'Reward Title is required'}
      />
      <Textarea
        label="Reward Description"
        value={description}
        resize="vertical"
        onChange={event => {
          setDescription(event.currentTarget.value);
          onRewardChange?.({
            ...internalReward,
            reward_description: event.currentTarget.value,
          });
        }}
        required
        minLength={50}
        error={getRewardDescriptionError(description)}
      />
      <TextInput
        label="Reward Image URL"
        value={image}
        onChange={event => {
          setImage(event.currentTarget.value);
          onRewardChange?.({
            ...internalReward,
            reward_image: event.currentTarget.value,
          });
        }}
        required
        error={
          (!image || !isValidLink(image)) &&
          'Reward Image URL is required and must be a valid URL (e.g. https://example.com)'
        }
      />
      {image && isValidLink(image) && (
        <Image
          src={image}
          fallbackSrc="https://placehold.co/70x70?text=Invalid Link"
          maw={'5rem'}
          alt="Reward Image"
        />
      )}
      <DateInput
        label="Expected Distribution Date"
        value={expectedDate ? new Date(expectedDate) : undefined}
        minDate={new Date()}
        onChange={date => {
          setExpectedDate(formatDate(date));
          onRewardChange?.({
            ...internalReward,
            extra_data: {
              ...internalReward.extra_data,
              expected_distribution_date: formatDate(date)
                .split('T')[0]
                .replace(/-/g, '/'),
            },
          });
        }}
        required
        error={!expectedDate && 'Expected Distribution Date is required'}
      />
      <Textarea
        label="Distribution Process"
        resize="vertical"
        value={distributionProcess}
        onChange={event => {
          setDistributionProcess(event.currentTarget.value);
          onRewardChange?.({
            ...internalReward,
            extra_data: {
              ...internalReward.extra_data,
              distribution_process: event.currentTarget.value,
            },
          });
        }}
        required
        error={!distributionProcess && 'Distribution Process is required'}
      />
    </Flex>
  );
};

export default RewardSection;
