import {
  Anchor,
  Button,
  Flex,
  Image,
  Modal,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent, useEffect } from 'react';
import { IoRocket } from 'react-icons/io5';
import { LocalImages } from '../../assets/images';
import { CampaignPublishStatus } from '../../services/campaign-data-service';

interface PublishDialogProps {
  hasUnpublishedChanges?: boolean;
  campaignPublishStatus?: CampaignPublishStatus;
  opened?: boolean;
  isLoading?: boolean;
  isTriggerLoading?: boolean;
  onPublish?: () => void;
}

export const PublishDialog: FunctionComponent<PublishDialogProps> = props => {
  const { campaignPublishStatus } = props;
  const [opened, { open, close }] = useDisclosure(props.opened);

  useEffect(() => {
    if (props.opened) {
      open();
    } else {
      close();
    }
  }, [props]);

  const getLabelValue = () => {
    if (props.hasUnpublishedChanges) {
      return 'You have no changes to publish.';
    }
    if (campaignPublishStatus?.canPublish === false) {
      const sectionsInError = campaignPublishStatus?.sectionsInError ?? [];
      const formattedSections = sectionsInError
        .map(section => section.charAt(0).toUpperCase() + section.slice(1))
        .join(', ')
        .replace(/, ([^,]*)$/, ' and $1');
      return `Make sure all the required fields are properly filled before publishing the changes. You have missing fields in:
      ${formattedSections} section${sectionsInError.length === 1 ? '' : 's'} in Game page.`;
    }
    if (campaignPublishStatus?.canPublish === true) {
      return 'You can publish your changes now.';
    }
    return '';
  };
  return (
    <>
      <Tooltip maw={'15rem'} multiline openDelay={500} label={getLabelValue()}>
        <Button
          leftSection={<IoRocket size="1rem" />}
          loading={props.isTriggerLoading}
          disabled={
            props.hasUnpublishedChanges || !campaignPublishStatus?.canPublish
          }
          onClick={open}
        >
          Publish
        </Button>
      </Tooltip>
      <Modal
        title={<Image w={'8rem'} src={LocalImages.logo} />}
        zIndex={600}
        opened={opened}
        onClose={close}
        centered
      >
        <Flex direction={'column'} gap={'lg'} p={'sm'} pt={0} pb={'0'}>
          <Flex direction={'column'} gap={'0.5rem'}>
            <Title order={3}>Publish</Title>
            <Text c={'gray'}>
              Onward and Upward! Click Publish to allow thousands of gamers to
              participate in your new loyalty program.
            </Text>
          </Flex>
          <Flex gap={'md'} justify={'center'} w={'100%'}>
            <Button variant="outline" w={'100%'} onClick={close}>
              Cancel
            </Button>
            <Button
              w={'100%'}
              loading={props.isLoading}
              color={'blue'}
              onClick={() => props.onPublish?.()}
            >
              Publish
            </Button>
          </Flex>
          <Flex w={'100%'} align={'center'} direction={'column'}>
            <Text c={'dark'} size="sm">
              By publishing you accept our{' '}
              <Anchor
                href="https://forge-gg.gitbook.io/the-fine-print"
                target="_blank"
                underline="hover"
                c={'blue'}
              >
                Terms of Services
              </Anchor>
            </Text>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
