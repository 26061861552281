import * as Frigade from '@frigade/react';
import { useMediaQuery } from '@mantine/hooks';
import { StrictMode, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SideBar } from '../../components/SideBar/SideBar';
import { ToolBar } from '../../components/ToolBar/ToolBar';
import { configuration } from '../../configuration';
import { GlobalContext } from '../../context/GlobalContext';
import { CampaignOption } from '../../data/campaign-option';
import { MessageStatus } from '../../data/notify-options';
import { getDefaultLinks } from '../../helper/navigation-link';
import { useNotification } from '../../hooks/use-notification';
import { useTier } from '../../hooks/use-tier';
import { ssPartnerService } from '../../services/api-client/ss-partner-service';
import { authentication } from '../../services/authentication';
import { localStorageService } from '../../services/local-storage-service';
import { Layout } from '../Layout/Layout';
export const MainLayout = () => {
  const notification = useNotification();
  const user = authentication.getUser();
  const {
    campaignOptions,
    organizations,
    loadingCampaignsOptions,
    setSelectCampaignOption,
    selectedCampaignOption,
    setCampaignOptionsTrigger,
    campaignOptionsTrigger,
    addingCampaign,
    setAddingCampaign,
    campaignPublishStatus,
    publishingCampaign,
    setPublishingCampaign,
    selectedOrganization,
    setSelectedOrganization,
    loadingSelectedCampaignData,
  } = useContext(GlobalContext);

  const [{ isFreeTier, component, isClose, setIsClose }] = useTier();
  const [publishDialogOpened, setPublishDialogOpened] =
    useState<boolean>(false);
  const [isAddingCampaignModalOpen, setIsAddingCampaignModalOpen] =
    useState<boolean>(false);
  const [localCampaignOptions, setLocalCampaignOptions] = useState<
    CampaignOption[]
  >([]);
  const [deletingCampaign, setDeletingCampaign] = useState<boolean>(false);
  useEffect(() => {
    setLocalCampaignOptions(campaignOptions ?? []);
  }, [campaignOptions]);

  const navigation = useNavigate();
  const location = useLocation();
  const [pathname, setPathname] = useState<string>(
    localStorageService.getActualPage() ?? ''
  );

  const tablet = useMediaQuery('(max-width: 51.25rem)');
  const mobile = useMediaQuery('(max-width: 25.875rem)');
  const [expanded, setExpanded] = useState<boolean>(tablet ? false : true);
  const [hidden, setHidden] = useState<boolean>(mobile ? true : false);

  const partnerSlug = selectedOrganization?.partnerSlug;
  const [addingDraft, setAddingDraft] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(!tablet);
    setHidden(!!mobile);
  }, [tablet, mobile]);

  useEffect(() => {
    setPathname(localStorageService.getActualPage() ?? '');
  }, [location]);

  const handleOnCampaignPublished = async () => {
    try {
      setPublishingCampaign(true);
      if (!partnerSlug || !selectedCampaignOption) {
        return;
      }
      await ssPartnerService.publishCampaign(
        partnerSlug,
        selectedCampaignOption.draft_campaign_id
      );
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error publishing campaign: ${error?.response?.data?.error_msg ?? 'Unknown error'}`
      );
    } finally {
      setPublishingCampaign(false);
      setPublishDialogOpened(false);
    }
  };

  const handleOrganizationChange = async (partner: string | null) => {
    if (!partner) {
      return;
    }
    const tempSelectedOrganization = organizations?.find(
      o => o.partner === partner
    );
    if (!tempSelectedOrganization) {
      return;
    }
    setSelectedOrganization(tempSelectedOrganization);
    localStorageService.setSelectedOrganization(tempSelectedOrganization);
  };

  const handleCampaignDelete = async (campaignOption: CampaignOption) => {
    try {
      setDeletingCampaign(true);
      if (!partnerSlug) {
        return;
      }
      await ssPartnerService.deleteDraftCampaign(
        partnerSlug,
        campaignOption.draft_campaign_id
      );
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    } catch (error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error deleting campaign: ${error?.response?.data?.error_msg ?? 'Unknown error'}`
      );
    } finally {
      setDeletingCampaign(false);
    }
  };

  const handleTierClick = () => {
    if (selectedOrganization?.tier === 'free') {
      setIsClose(false);
    }
  };

  const handelAddNewDraft = async (cloneOption: boolean) => {
    try {
      setAddingDraft(true);
      setAddingCampaign(true);
      if (!partnerSlug) {
        return;
      }
      if (cloneOption) {
        const campaignId = selectedCampaignOption?.draft_campaign_id;
        if (!campaignId) {
          return;
        }
        await ssPartnerService.cloneDraftCampaign(partnerSlug, campaignId);
      } else {
        await ssPartnerService.addDraftCampaign(partnerSlug);
      }
    } catch (error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error adding new draft: ${error.response.data.error_msg ?? 'Unknown error'}`
      );
    } finally {
      setAddingDraft(false);
      setAddingCampaign(false);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
      setIsAddingCampaignModalOpen(false);
    }
  };

  return (
    <Frigade.Provider
      groupId={user?.sub as string}
      groupProperties={{
        name: user?.sub as string,
      }}
      apiKey={configuration.frigade.apiKey}
      userId={user?.sub as string}
    >
      <StrictMode>
        <Layout
          variant="FullSidebar"
          sidebarExpanded={expanded}
          sidebarHidden={hidden}
          isControlledSidebar
          sideBar={
            <SideBar
              membership={selectedOrganization?.tier ?? ''}
              links={getDefaultLinks()}
              expanded={expanded}
              defaultItemSlug={pathname.split('/')[1]}
              onMenuClick={() => setExpanded(false)}
              onItemSelected={slug => {
                navigation(slug);
              }}
              onTierClick={handleTierClick}
            />
          }
          toolBar={
            <ToolBar
              campaignPublishStatus={campaignPublishStatus}
              selectedOrganization={selectedOrganization}
              organizations={organizations ?? []}
              onOrganizationChange={partnerSlug => {
                handleOrganizationChange(partnerSlug);
              }}
              handleCampaignDelete={handleCampaignDelete}
              deletingCampaign={deletingCampaign}
              expanded={expanded}
              onMenuClick={() => setExpanded(true)}
              name={user?.name}
              profileImage={user?.picture}
              onLogoutClick={() => authentication.logout('logout')}
              campaignOptions={localCampaignOptions}
              onAddCampaign={cloneOption => handelAddNewDraft(cloneOption)}
              addingCampaign={addingDraft || addingCampaign}
              loadingCampaignsOptions={loadingCampaignsOptions}
              onCampaignSelection={setSelectCampaignOption}
              selectedCampaignOption={selectedCampaignOption}
              publishingCampaign={publishingCampaign}
              onPublish={handleOnCampaignPublished}
              loadingCampaignData={loadingSelectedCampaignData}
              publishDialogOpened={publishDialogOpened}
              isAddingCampaignModalOpen={isAddingCampaignModalOpen}
            />
          }
        >
          {isFreeTier && !isClose && component}
          <Outlet />
        </Layout>
      </StrictMode>
    </Frigade.Provider>
  );
};
