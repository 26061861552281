import { Button, Divider, Flex } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GameEditionSection from '../../components/Game/GameEditionSection';
import { GlobalContext } from '../../context/GlobalContext';
import { Campaign } from '../../data/campaign';
import { CampaignAssetModification } from '../../data/campaign-assets';
import { getSkeletons } from '../../helper/skeletons';
import { useFromCheckList } from '../../hooks/use-from-checklist';
import { useNotification } from '../../hooks/use-notification';
import { campaignService } from '../../services/api-client/campaign-service';

import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlags } from '../../data/feature-flags';
import { MessageStatus } from '../../data/notify-options';
import { Path } from '../../data/paths';
import { RewardInfo } from '../../data/reward-info';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { ssPartnerService } from '../../services/api-client/ss-partner-service';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const notification = useNotification();
  const isRewardActive = useFeatureFlagEnabled(
    FeatureFlags.ShowRewardsConfiguration
  );
  const {
    loadingCampaignsOptions,
    selectedCampaignData,
    loadingSelectedCampaignData,
    campaignOptionsTrigger,
    setCampaignOptionsTrigger,
    setSelectedCampaignDataTrigger,
    selectedCampaignDataTrigger,
    setAddingCampaign,
    addingCampaign,
    selectedOrganization,
    publishingCampaign,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [addingDraft, setAddingDraft] = useState<boolean>(false);
  const [isFromCheckList, setIsFromChecklist] = useFromCheckList();
  const [savingEditedGame, setSavingEditedGame] = useState<boolean>(false);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [assets, setAssets] = useState<CampaignAssetModification>({});
  const [selectedCampaign, setSelectedCampaign] = useState<
    Campaign | undefined
  >(selectedCampaignData);

  useEffect(() => {
    setSelectedCampaign(selectedCampaignData);
    setEnableSave(false);
  }, [selectedCampaignData]);

  const [rewardsData, loadingRewardsData, setRewardData] =
    useDataFetcher<RewardInfo>({
      serviceCall() {
        if (selectedOrganization && selectedCampaignData) {
          return ssPartnerService.getRewardInfo(
            selectedOrganization.partnerSlug,
            selectedCampaignData.campaign_id
          );
        }
      },
      dependencies: [selectedCampaignData, selectedCampaignDataTrigger],
      conditionForExecution: isRewardActive,
    });

  const handleSaveEditedGame = async () => {
    if (!selectedCampaign || !selectedOrganization) {
      return;
    }
    try {
      setSavingEditedGame(true);
      await campaignService.edit(selectedCampaign);
      await saveAssets();
      await saveRewards();
      notification.notify(
        MessageStatus.Success,
        'Success',
        'Campaign edited successfully'
      );
      setSelectedCampaignDataTrigger(!selectedCampaignDataTrigger);
    } catch (error: any) {
      notification.notifySelfServiceError({
        status: MessageStatus.Error,
        title: 'Game Error',
        description: 'An error occurred while saving the game',
        response: error.response,
      });
    } finally {
      finalizeSave();
    }
  };

  const saveAssets = async () => {
    const assetsPromises = [
      campaignService.saveScreenShotsAssets(
        assets,
        selectedCampaign!.campaign_id
      ),
      campaignService.saveIconAsset(assets, selectedCampaign!.campaign_id),
      campaignService.saveBackgroundAsset(
        assets,
        selectedCampaign!.campaign_id
      ),
      campaignService.savePoster(assets, selectedCampaign!.campaign_id),
      campaignService.saveBannerAsset(assets, selectedCampaign!.campaign_id),
      campaignService.saveWeekQuestsBgAsset(
        assets,
        selectedCampaign!.campaign_id
      ),
    ];
    await Promise.all(assetsPromises);
  };

  const saveRewards = async () => {
    if (
      rewardsData &&
      selectedCampaign &&
      selectedOrganization &&
      isRewardActive
    ) {
      await ssPartnerService.saveRewardsInfo(
        rewardsData,
        selectedCampaign,
        selectedOrganization.partnerSlug
      );
    }
  };

  const finalizeSave = () => {
    setEnableSave(false);
    setSavingEditedGame(false);
    setCampaignOptionsTrigger(!campaignOptionsTrigger);
    if (isFromCheckList) {
      setIsFromChecklist(false);
      navigate(Path.Home);
    }
  };

  const handelAddNewDraft = async () => {
    try {
      setAddingDraft(true);
      setAddingCampaign(true);
      if (!selectedOrganization) {
        return;
      }
      await ssPartnerService.addDraftCampaign(selectedOrganization.partnerSlug);
    } catch (error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error adding new draft: ${error?.response?.data?.message ?? 'Unknown error'}`
      );
    } finally {
      setAddingDraft(false);
      setAddingCampaign(false);
      setCampaignOptionsTrigger(!campaignOptionsTrigger);
    }
  };

  if (
    loadingSelectedCampaignData ||
    loadingCampaignsOptions ||
    savingEditedGame ||
    publishingCampaign ||
    loadingRewardsData
  ) {
    return (
      <Flex
        align={'center'}
        gap={'1rem'}
        direction={'column'}
        justify={'center'}
        h={'100%'}
        w={'100%'}
        p={'2rem'}
      >
        {getSkeletons(10)}
      </Flex>
    );
  }

  const onGameChange = (
    campaign: Campaign,
    assets: CampaignAssetModification,
    reward: RewardInfo
  ) => {
    setSelectedCampaign(campaign);
    setAssets(assets);
    setRewardData(reward);
  };

  return (
    <Flex
      direction={'column'}
      className="GamePage"
      w={'100%'}
      align={'center'}
      h={'100%'}
    >
      <Flex
        direction={'column'}
        w={'100%'}
        pos={'sticky'}
        top={0}
        style={{ zIndex: 200 }}
        bg={'#f9fafb'}
      >
        <Flex align={'center'} justify={'flex-end'} gap={'md'} p={'xs'}>
          <Button
            loading={savingEditedGame}
            disabled={!enableSave}
            onClick={handleSaveEditedGame}
          >
            Save
          </Button>
        </Flex>
        <Divider />
      </Flex>
      <GameEditionSection
        rewardsData={rewardsData}
        campaign={selectedCampaignData}
        addingDraft={addingDraft || addingCampaign}
        addNewDraft={handelAddNewDraft}
        onGameChange={onGameChange}
        setSaveEnabled={setEnableSave}
      />
    </Flex>
  );
};

export const GamePage = withAuthProtection(Page);
