import {
  Anchor,
  Avatar,
  Button,
  Flex,
  FlexProps,
  Loader,
  Menu,
  Select,
  Text,
  Tooltip,
} from '@mantine/core';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { BiCheck, BiLogOut, BiUser } from 'react-icons/bi';
import { FaEye } from 'react-icons/fa';
import { MdArrowDropDown } from 'react-icons/md';
import { configuration } from '../../configuration';
import { CampaignOption } from '../../data/campaign-option';
import { Organization } from '../../data/organization';
import { CampaignPublishStatus } from '../../services/campaign-data-service';
import CampaignSelector from '../Campaign/CampaignSelector/CampaignSelector';
import { PublishDialog } from '../PublishDialog/PublishDialog';
import './ToolBar.scss';

export interface AvatarMenuItem {
  displayName: string;
  icon: ReactNode;
  onClick?: () => void;
}

export interface ToolBarProps {
  onOrganizationChange?: (partnerSlug: string | null) => void;
  organizations: Organization[];
  selectedOrganization?: Organization;
  name?: string;
  profileImage?: string;
  avatarMenuItems?: AvatarMenuItem[];
  expanded?: boolean;
  onLogoutClick?: () => void;
  onMenuClick?: () => void;
  onSearchClick?: (value: string) => void;
  style?: FlexProps;
  campaignOptions?: CampaignOption[];
  onAddCampaign?: (cloneOption: boolean) => void;
  onCampaignSelection?: (campaignOption: CampaignOption) => void;
  handleCampaignDelete?: (campaignOption: CampaignOption) => void;
  deletingCampaign?: boolean;
  selectedCampaignOption?: CampaignOption;
  loadingCampaignsOptions?: boolean;
  addingCampaign?: boolean;
  publishingCampaign?: boolean;
  campaignPublishStatus?: CampaignPublishStatus;
  onPublish?: () => void;
  loadingCampaignData?: boolean;
  publishDialogOpened?: boolean;
  isAddingCampaignModalOpen?: boolean;
}

export const ToolBar: FunctionComponent<ToolBarProps> = props => {
  const {
    onAddCampaign,
    onCampaignSelection,
    handleCampaignDelete,
    deletingCampaign,
    selectedCampaignOption,
    loadingCampaignsOptions,
    addingCampaign,
    onOrganizationChange,
    selectedOrganization,
    publishingCampaign,
    onPublish,
    campaignPublishStatus,
    loadingCampaignData,
    publishDialogOpened,
  } = props;
  const baseUrl = configuration.forgeApi.baseUrl.replace('/api', '');
  const [isPublishDialogOpened, setIsPublishDialogOpened] =
    useState(publishDialogOpened);
  const [isAddingCampaignModalOpen, setIsAddingCampaignModalOpen] = useState(
    props.isAddingCampaignModalOpen
  );

  const [isPreview, setIsPreview] = useState(false);
  const [campaignOptions, setCampaignOptions] = useState<
    CampaignOption[] | undefined
  >(props.campaignOptions);

  const [organizations, setOrganizations] = useState<Organization[]>(
    props.organizations
  );

  useEffect(() => {
    setOrganizations(props.organizations);
  }, [props.organizations]);

  useEffect(() => {
    setIsAddingCampaignModalOpen(props.isAddingCampaignModalOpen ?? false);
  }, [props.isAddingCampaignModalOpen]);

  useEffect(() => {
    setIsPublishDialogOpened(publishDialogOpened ?? false);
  }, [publishDialogOpened]);

  useEffect(() => {
    const tempIsPreview =
      campaignOptions?.find(
        x => x.draft_campaign_id === selectedCampaignOption?.draft_campaign_id
      )?.published === false;
    setIsPreview(tempIsPreview);
    return;
  }, [selectedCampaignOption]);

  const getPreviewLink = () => {
    return `${baseUrl}/game/${selectedOrganization?.gameName}/${selectedCampaignOption?.draft_campaign_id}?preview=true`;
  };

  const getCampaignPageUrl = () => {
    return `${baseUrl}/game/${selectedOrganization?.gameName}`;
  };

  useEffect(() => {
    setCampaignOptions(props.campaignOptions);
  }, [props.campaignOptions]);

  const getSortedOrganizations = () => {
    if (!organizations) {
      return [];
    }
    return organizations.sort((a, b) => a.partner.localeCompare(b.partner));
  };
  return (
    <Flex
      w={'100%'}
      h={'100%'}
      bg="#fff"
      align={'center'}
      justify={'space-between'}
      p={'16px 32px'}
      gap={'md'}
      classNames={{ root: 'ToolBar' }}
      {...props.style}
    >
      <Flex align={'center'} direction={'row'} gap={'sm'}>
        <CampaignSelector
          campaignOptions={campaignOptions}
          onAddCampaign={cloneOption => onAddCampaign?.(cloneOption)}
          onCampaignSelection={onCampaignSelection}
          handleCampaignDelete={handleCampaignDelete}
          deletingCampaign={deletingCampaign}
          selectedCampaignId={selectedCampaignOption?.draft_campaign_id}
          loadingCampaignsOptions={loadingCampaignsOptions}
          addingCampaign={addingCampaign}
          isModalOpen={isAddingCampaignModalOpen ?? false}
        />
      </Flex>
      <Flex gap={'16px'} align={'center'}>
        {campaignOptions && campaignOptions.length > 0 && (
          <Tooltip label={getPreviewLink()} openDelay={500}>
            <Anchor href={getPreviewLink()} target="_blank">
              <Button
                variant="outline"
                leftSection={<FaEye size="1rem" />}
                loading={loadingCampaignsOptions}
              >
                Preview
              </Button>
            </Anchor>
          </Tooltip>
        )}
        <PublishDialog
          opened={isPublishDialogOpened ?? false}
          isLoading={publishingCampaign}
          onPublish={onPublish}
          isTriggerLoading={
            publishingCampaign ||
            loadingCampaignsOptions ||
            addingCampaign ||
            loadingCampaignData
          }
          hasUnpublishedChanges={!selectedCampaignOption?.unpublished_changes}
          campaignPublishStatus={campaignPublishStatus}
        />

        {campaignOptions && campaignOptions.length > 0 && !isPreview && (
          <Tooltip label={getCampaignPageUrl()} openDelay={500}>
            <Anchor href={getCampaignPageUrl()} target="_blank">
              <Button loading={loadingCampaignsOptions}>{'Game page'}</Button>
            </Anchor>
          </Tooltip>
        )}
        <Menu
          classNames={{
            dropdown: 'Dropdown',
            item: 'Item',
            itemSection: 'ItemSection',
          }}
          shadow="lg"
          position="bottom-end"
          offset={7}
        >
          <Menu.Target>
            <Flex
              className="AvatarContainer"
              align={'center'}
              c="#1F374E"
              gap={8}
            >
              <Avatar
                variant="filled"
                size={'32px'}
                color="#1F374E"
                alt={props.name}
                src={props.profileImage}
              >
                <BiUser size={'1.5rem'} />
              </Avatar>
              <Flex align={'center'}>
                <Flex direction={'column'}>
                  <Text>{props.name}</Text>
                  <Flex w={'100%'} justify={'end'}>
                    <Text size="xs">{selectedOrganization?.partner}</Text>
                  </Flex>
                </Flex>

                <MdArrowDropDown size={'1.5rem'} color="#6085A7" />
              </Flex>
            </Flex>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu
              classNames={{
                dropdown: 'Dropdown',
                item: 'Item',
                itemSection: 'ItemSection',
              }}
              width={200}
              shadow="md"
              position="left-start"
              closeOnItemClick={true}
            >
              {organizations && organizations.length !== 0 ? (
                <Select
                  style={{ cursor: 'pointer' }}
                  label="Switch Organizations"
                  labelProps={{ pl: 'sm' }}
                  leftSection={<BiCheck size={'1rem'} />}
                  data={getSortedOrganizations().map(item => item.partner)}
                  onChange={e => onOrganizationChange?.(e)}
                  defaultValue={selectedOrganization?.partner.toString()}
                  searchable={organizations && organizations.length >= 5}
                />
              ) : (
                <Flex w={200} justify={'center'}>
                  <Loader size={'sm'} />
                </Flex>
              )}
            </Menu>
            <Menu.Divider />
            <Menu.Item
              leftSection={<BiLogOut size={'1.6875rem'} />}
              onClick={props.onLogoutClick}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
    </Flex>
  );
};
