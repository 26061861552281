import { configuration } from '../../configuration';
import { Campaign } from '../../data/campaign';
import {
  CampaignOption,
  CampaignOptionResponse,
} from '../../data/campaign-option';
import { RewardInfo } from '../../data/reward-info';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class SsPartnerService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'partner');
  }

  public async deleteDraftCampaign(
    partnerSlug: string,
    draft_campaign_id: string
  ) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${draft_campaign_id}/delete-draft`),
      {}
    );
  }

  public async saveRewardsInfo(
    rewardsData: RewardInfo,
    selectedCampaign: Campaign,
    partnerSlug: string
  ) {
    await this.apiClient.post(
      this.url(
        `${partnerSlug}/campaign/${selectedCampaign.campaign_id}/reward`
      ),
      rewardsData
    );
  }

  public async getRewardInfo(
    partnerSlug: string,
    campaignId: string
  ): Promise<RewardInfo> {
    const rewardConfig = await this.apiClient.get<{ data: RewardInfo }>(
      this.url(`${partnerSlug}/campaign/${campaignId}/reward`)
    );
    return rewardConfig.data;
  }

  public async addDraftCampaign(partnerSlug: string) {
    await this.apiClient.post(this.url(`${partnerSlug}/campaign/create`), {});
  }

  public async cloneDraftCampaign(partnerSlug: string, campaignId: string) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${campaignId}/clone`),
      {
        leaderboard: true,
        nft_multiplier: true,
        social_multiplier: true,
        referrer_multiplier: true,
        milestone: true,
        quests: true,
        contracts: true,
        tweets: true,
        twitter_accounts: true,
        twitter_cashtags: true,
        campaign_reward: true,
      }
    );
  }

  public async getDraftCampaigns(
    partnerSlug: string
  ): Promise<CampaignOption[]> {
    const response = await this.apiClient.get<CampaignOptionResponse>(
      this.url(`${partnerSlug}/get-draft-campaigns`)
    );

    if (!response) {
      return [];
    }
    const sortedCampaignOptions = response.draft_campaigns?.sort((a, b) => {
      if (a.published && !b.published) {
        return -1;
      } else if (!a.published && b.published) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedCampaignOptions;
  }
  public async publishCampaign(partnerSlug: string, campaignId: string) {
    await this.apiClient.post(
      this.url(`${partnerSlug}/campaign/${campaignId}/push`),
      {}
    );
  }
}

export const ssPartnerService = new SsPartnerService(
  new BearerTokenApiClient(configuration.forgeApi.selfServiceUrl)
);
