import { Divider } from '@chakra-ui/react';
import { Flex, ScrollAreaAutosize } from '@mantine/core';

import { useContext, useState } from 'react';
import OrganizationAdditionForm from '../../components/Organizations/OrganizationAdditionForm/OrganizationAdditionForm';
import OrganizationList from '../../components/Organizations/OrganizationList/OrganizationList';
import { GlobalContext } from '../../context/GlobalContext';
import { MessageStatus } from '../../data/notify-options';
import { Organization, OrganizationStructure } from '../../data/organization';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useNotification } from '../../hooks/use-notification';
import { organizationService } from '../../services/api-client/organization-service';
import { ssOrganizationService } from '../../services/api-client/ss-create-organization';
import { authentication } from '../../services/authentication';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const userEmail = authentication.getUser()?.email;
  const notification = useNotification();
  const { setOrganizationsTrigger, organizationsTrigger } =
    useContext(GlobalContext);
  const [organizationRefresh, setOrganizationRefresh] =
    useState<boolean>(false);
  const [addingOrganization, setAddingOrganization] = useState<boolean>(false);
  const [organizations, isLoading] = useDataFetcher<Organization[]>({
    serviceCall() {
      if (userEmail) {
        return organizationService.getOrganizationsByEmail(userEmail);
      }
    },
    dependencies: [userEmail, organizationRefresh],
    conditionForExecution: !!userEmail,
    onError(error: any) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error fetching organizations: ${error.response?.data.message ?? 'Unknown error'}`
      );
    },
  });

  const handelOrganizationSave = async (
    organizationStructure: OrganizationStructure
  ) => {
    try {
      setAddingOrganization(true);
      await ssOrganizationService.create(organizationStructure);
      await organizationService.generateStripeAccount(organizationStructure);
      setOrganizationsTrigger(!organizationsTrigger);
      setOrganizationRefresh(!organizationRefresh);
      notification.notify(
        MessageStatus.Success,
        'Success',
        `Organization saved successfully`
      );
    } catch (error) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        `Error saving organization`
      );
    } finally {
      setAddingOrganization(false);
    }
  };

  return (
    <Flex
      w={'100%'}
      direction={'column'}
      gap={'1rem'}
      align={'center'}
      p={'1rem'}
    >
      <Flex
        direction={'column'}
        pos={'sticky'}
        top={0}
        w={'100%'}
        justify={'end'}
        style={{ zIndex: 200 }}
        bg={'#f9fafb'}
        gap={'0.5rem'}
      >
        <Flex justify={'end'}>
          <OrganizationAdditionForm
            addingOrganization={addingOrganization}
            onSave={organizationStructure =>
              handelOrganizationSave(organizationStructure)
            }
          />
        </Flex>
        <Divider />
      </Flex>
      <ScrollAreaAutosize w={'100%'} mah={'45rem'}>
        <Flex>
          <OrganizationList
            organizations={organizations}
            isLoading={isLoading}
          />
        </Flex>
      </ScrollAreaAutosize>
    </Flex>
  );
};

export const OrganizationManagementPage = withAuthProtection(Page);
